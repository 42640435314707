.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.major-mono-display-regular {
    font-family: "Major Mono Display", monospace;
    font-style: normal;
}
