.bell-curve-slider-container {
    width: 300px;
    margin: auto;
    background-color: white; 
}


.bell-curve-slider-label {
    background-color: white;
    font-size: small;
    padding: 3px 5px;
    border-radius: 10px;
}

.bell-curve-info-button-container {
    position: relative;
    width: 0px;
    height: 0px;
    top: -17px;
    left: 270px;
    background-color: #f0f0f0;
}

.bell-curve-label {
    background-color: white;
    font-size: small;
    margin: 5px auto;
    padding: 1px ;
    width: 200px;
    border-radius: 4px;
    color: #505050;
    text-align: center;
}

.bell-curve-label-container {
    display: flex;
    margin-top: 10px;
    background-color: #f0f0f0;
}

.bell-curve-slider-label {
    text-align: center;
    align-items: center;
}

.bell-curve-slider {
    width: 100%;
    height: 60px;
}


.bell-curve-ticker {
    fill: var(--theme-identifier-color);
}



/*Custom Slider Logic https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/*/

.condition-range {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 10rem;
}

.condition-range::-webkit-slider-runnable-track {
    background: #e0e0e0;
    height: 0.1rem;
    border-radius: 10px;
}

.condition-range::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */
    background-color: var(--theme-identifier-color);
    height: 0.65rem;
    width: 0.65rem;    
    border-radius: 10px;
}