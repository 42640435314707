
.loading {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.loading-widget {
    padding: 15px;
}

.loading-message {
    padding: 15px;
}
