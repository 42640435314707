
.information-pop-up-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.information-pop-up-container {
    position: relative;
    width: 500px;
    height: 75%;
    display: flex;
    border-radius: 5px;
    justify-content: left;
    align-items: top;
    text-align: left;
    background-color: white;
    overflow-y: scroll;
}

.info-header {
    padding-top: 35px;
    color: var(--theme-font-grey);
    color: black;
    font-weight: 300;
    font-size: 20px;
}

.info-section {
    padding-left: 25px;
    padding-right: 25px;
    color: var(--theme-font-dark);
    font-weight: 300;
}

.remove-info-button {
    position: sticky;
    top: 10px; 
    right: 10px; 
    border: none;
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    background-color: #f6f6f6;
    padding: 0;
    color: #909090;
}

.info-button {
    margin-left: 10x;
    margin-right: 10px;
    margin-bottom: 1.5px;
    height: 25px;
    line-height: 33px;
    width: 25px;
    display: flex;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    align-items: center;     
    justify-content: center; 
    font-size: 14px;         
    font-weight: 100;
    color: black;
    border: none;
    cursor: pointer;   
}

.info-button:hover {
    background-color: #f6f6f6;
}

.info-button:active {
    background-color: #eaeaea;
}

@media (max-width: 600px) {
    .information-pop-up-container{
        width: 80%;
    }
}