.header {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.valuations-home-page {
    background-image: url('../../public/home_page_image.jpg');
    width: 100%;
    height: 800px;
    background-position: center; 
    background-size: cover; 
}

.body {
    width: 100%;
    margin: 0 auto;
}

.footer {
    display: flex;
    border: 1px solid #e0e0e0;
    width: 100%;
    height: auto;
    text-align: center;
    justify-content: center;
}

.footer-item {
    width: 33%;
}

.footer-info {
    color: #303030;
    font-weight: 300;
    font-size: var(--default-font-size);
    padding: 15px;
    margin: auto;
    flex-wrap: wrap;
}

.footer-header {
    padding-top: 35px;
    color: #303030;
    font-weight: 300;
    font-size: var(--default-header-font-size);
}

.website-and-logo-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center; 
    height: 80px;
}

.website-name-container {
    width: 170px;
    font-size: 30px;
    font-weight: 400;
}

.website-name {
    font-size: 20px;
    color: black;
    font-weight: 800;
    text-align: center;
}

.valuation-description {
    padding-top: 80px;
    height: 100px;
    font-size: 38px;
    stroke: white;
    fill: white;
    font-weight: 500;
    text-align: center;
    margin: 5px;
    color: white;
}

@media (max-width: 1000px) {
    .header {
        display: inline-block;
        align-items: center; 
        text-align: center;
    }
}