
.toggle-button {
    stroke: var(--theme-font-grey);
    stroke-width: 1px;
    margin-left: 2.5px;
    margin-bottom: 1px;
    padding: 3px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border: none;
    border-radius: 50%;
    background-color: white;
}

.toggle-button:hover {
    background-color: #f6f6f6;
}

.toggle-button:active {
    background-color: #eaeaea;
}

.toggle-icon {
    width: 100%;
    height: 100%;
}