
.logo-container {
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100%;
    height: 100%;
}

.logo-background {
    fill: #5cd5eb;
}

.logo-door {
    fill: orange;
    stroke: var(--logo-stroke-colour);
    stroke-width: var(--logo-thick-stroke-width);
}

.logo-door-knob {
    fill: orange;
    stroke: var(--logo-stroke-colour);
    stroke-width: var(--logo-thick-stroke-width);
}

.logo-grass {
    fill: white;
    stroke: var(--logo-stroke-colour);
    stroke-width: var(--logo-thick-stroke-width);
}

.logo-roof {
    fill: black;
    stroke: var(--logo-stroke-colour);
    stroke-width: 0;
}

.logo-wall {
    fill: white;
    stroke: var(--logo-stroke-colour);
    stroke-width: var(--logo-thick-stroke-width);
}

.logo-pavement {
    fill: black;
    stroke: var(--logo-stroke-colour);
    stroke-width: var(--logo-thick-stroke-width);
}
