
form {
    text-align: center;
    margin: auto;
    padding: 20px 0px;
    width: fit-content;
}

fieldset {
    text-align: center;
    justify-content: center;
    background-color: white;
    margin: 10px auto;
    border-radius: 4px;
    border-color: white;
    border-style: solid;
    padding: 6px 18px;
    padding-bottom: 7px;
}

.advanced-options {
    text-align: center;
    justify-content: center;
    background-color: white;
    margin: 10px auto;
    border-radius: 4px;
    border-color: white;
    border-style: solid;
    padding: 6px 18px;
    padding-bottom: 7px;
    width: fit-content;
}

.field {
    border-radius: 4px;
    border-width: 2px 5px;
    background-color: white;
    border-color: white;
    border-style: solid;
    margin: 2.5px 2.5px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #404040;
    height: 22px;
    padding: 0px;
}

.temp {
    border-color: black;
    border-color:  #e9e9e9;
    background-color: #e9e9e9;
    background-color: #e0e0e0;
}

.address-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px 8px;
}

.main-form {
    justify-content: center;
    margin: auto;
    align-items: center;
}

.county-field {
    width: 105px;
}

.country-field {
    width: 105px;
}

.town-field {
    width: 102px;
}

.valuation-button {
    height: 35px;
    width: 100px;
    color: #404040;
    background-color: #F0F0F0;
}

.street-field {
    width: 200px;
}


.update-valuation-button {
    height: 35px;
    width: 100px;
    color: #404040;
    background-color: #F0F0F0;
}


.update-valuation-button:hover {
    background-color: #e0e0e0;
}

.valuation-button:hover {
    background-color: #e0e0e0;
}

input, select {
    box-sizing: border-box;
    margin: 0;
    line-height: normal;
}

@media (max-width: 1000px) {
    .address-field {
        display: inline-block;
    }
    .field {
        margin: 3px 2.5px;
    }
}