
.special-title {
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    height: 50px;
    padding: 0px;
    margin: 0px;
    color: var(--theme-font-grey);
    fill: var(--theme-font-dark);
}

.property-title {
    padding: 0px;
}

.valuation-title {
    margin: 16px 0px;
    font-size: 16px;
    width: 75px;
}

.info-title-container {
    display: flex;
    margin: auto;
    padding: 0px;
    justify-content: center;
    align-items: center;
    width: 220px;
}

.info-button-container {
    display: relative;
    width: 0px;
    height: 0px;
    margin-bottom: 27px;
}


.comparable-homes-title {
    margin: 16px 0px;
    padding: 0px;
    font-size: 16px;
    max-width: fit-content;
    width: 150px;
}

.valuation-container {
    display: flex;
    width: 75%;
    margin: auto;
    margin-bottom: 100px;
}

.longterm-valuation {
    width: 100%;
    height: 300px;
}

.valuation-visual-partition {
    flex: 3;
    height: 450px;
    width: auto;
}

.valuation-data-partition {
    flex: 3;
    height: 450px;
    width: auto;
}


.valuation-result-bell-curve-marker {
    fill: var(--theme-identifier-color); 
    stroke: var(--theme-identifier-color); 
    stroke-width: 2.5;
}

.valuation-result-bell-curve-annotation-mark {
    stroke: var(--theme-identifier-color); 
    stroke-width: 3;
}

.valuation-result-bell-curve-annotation-text {
    fill: var(--theme-font-grey)
}

.widget {
    border-style: none;
    border-radius: 4px;
    background-color: var(--theme-color); 
}


.comparable-homes-container {
    justify-content: center; 
    align-items: center;
    text-align: center;
    width: 95%;
    margin: auto;
    height: 330px;
}

.comparable-homes {
    padding: 0px;
    display: inline-block;
    line-height: normal;
    background-color: white;  
    font-size: 12px;
    border-radius: 4px;
    border-collapse: collapse;
    height: 90%; 
    overflow: auto;
    th, td {
        justify-content: center; /* Center horizontally */
        align-items: center;
        padding: 2px 10px; 
        border-radius: 2px;
        max-height: 12px;
        border: 1px solid #e0e0e0
    }
    td.x-button-container {
        padding: 2px 10px; 
    }
    th {
        font-weight: 400;
        background-color: var(--theme-identifier-color); 
    }
    thead {
        position: sticky;
        top: 0;
        color: white;
        z-index: 0;
    }
    table{
        margin: auto;
    }
}

.comparable-homes-table-row {
    justify-content: center; /* Center horizontally */
    align-items: center;
}

.x-button-container {
    padding: 0px
}

.x-button {
    text-align: center;
    vertical-align: middle; /* Align vertically */
    width: 13px;
    height: 13px;
    padding: 0px;
    border: none;
    border-radius: 0x;
    background-color: white;
    color: red;
    line-height: 3px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.x-button:hover {
    background-color: antiquewhite;
}

.x-button:active {
    background-color: red
}

.element {
    background-color: white;
    color: var(--theme-font-grey);
}

@media (max-width: 1000px) {
    .valuation-container {
        display: inline-block;
    }

    .comparable-homes-container {
        width: 100%
    }
}
