
.error-container {
    background-color: lightyellow;
    border-color: yellow;
    border-style: double;
    border-radius: 10px;
    color: var(--theme-font-grey);
    padding: 30px;
    margin: auto;
    text-align: center;
    width: 30%;
}

.error-warning {
    padding: 10px;
}

.error-message {
    padding: 10px;
}