:root {
  --theme-color: white;
  --theme-identifier-color: #5cd5eb;
  --theme-grey-color: #f0f0f0;
  --theme-font-grey: #808080;
  --theme-font-dark: #303030;
  --logo-thin-stroke-width: 1;
  --logo-thick-stroke-width: 0;
  --logo-stroke-colour: white;
  --logo-grey-colour: #d0d0d0;
  --small-ui-width: 1000px;
  --default-font-size: 12px;
  --default-header-font-size: 16px;
}

@import url("css/Fonts.css");
@import url("css/BellCurveSlider.css");
@import url("css/ToggleButton.css");
@import url("css/Forms.css");
@import url("css/Widgets.css");
@import url("css/Layout.css");
@import url("css/Logo.css");
@import url("css/Loading.css");
@import url("css/Error.css");
@import url("css/InfoButton.css");

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    font-size: var(--default-font-size)
}

input, select, button {
    font-size: var(--default-font-size)
}

.App {
    text-align: center;
}

.container {
    display: flex;
}

.partition {
    flex: 1;
}

.address-field {
    align-items: center;
    padding: auto;
    margin: auto;
}

.address-field {
    align-items: center;
}
